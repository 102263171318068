.dealer {
    &_infos {
        form {
            input {
                padding: 0;
                border: none;
            }
        }

        .panel {
            h2 {
                margin-left: 0;
                color: $secondaryColor;
            }
        }

        &-border_bottom {
            font-size: 1.4rem;

            & > .grid:not(:first-child) {
                margin-top: 15px;
                padding-top: 15px;
                border-top: 1px $takumiLighter solid;
            }
        }

        &-people {
            .grid {
                flex-wrap: wrap;
                width: 100%;
                color: $takumiLight;

                & > * {
                    width: calc((100% - 15px * 3) / 4);
                    margin-left: 0;
                    padding-right: 15px;
                    margin: 10px 0;
                    border-right: 1px $takumiLighter solid;

                    &:nth-child(4n), &:last-child {
                        border-right: 0;
                    }
                }
            }

            &-name {
                color: $black;
            }
        }
    }
}
