@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading_spinner {
    &-container {
        display: grid;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background: rgba($grey, 0.85);
    }

    &-content {
        width: 50px;
        height: 50px;
        border: 10px solid $darkGrey;
        border-top: 10px solid $secondaryColor;
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
    }
}
