table {
    width: 100%;
    border: 1px solid $blueGrey;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
        border-top: 1px solid $blueGrey;

        th {
            border-bottom: 1px solid $blueGrey;
            text-align: left;

            .sort {
                float: right;

                &-item {
                    &:first-of-type {
                        margin-left: 10px;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                &-current {
                    font-weight: bold;
                }
            }
        }
    }

    tbody {
        td.actions {
            width: 1px;

            &.microlist-actions span:hover {
                cursor: pointer;
            }
        }
    }

    th, td {
        padding: 15px 20px;
        border-top: 1px solid $blueGrey;
        vertical-align: top;

        &:not(:first-child) {
            border-left: 1px solid $blueGrey;
        }
    }

    &.compact {
        th, td {
            padding: 8px 20px;
        }
    }

    tbody tr:hover {
        background: rgba($takumiLighter, 0.2);
    }
}

.table-actions {
    width: 1px;
    vertical-align: middle;

    i {
        font-size: 20px;
        cursor: pointer;
        color: $secondaryColor;
    }
}
