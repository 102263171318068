.charts {
    &-title {
        break-before: always;
    }
    &_columns {
        margin: 0 -20px;

        @media screen and (min-width: $breakpointDesktop) {
            display: flex;
            justify-content: space-between;

            &.two-thirds {
                & > .charts_column {
                    &:first-of-type {
                        width: 62%;
                    }
                    &:last-of-type {
                        width: 36%;
                    }
                }
            }
        }

    }
    &_column {
        margin: 0 20px;
        width: calc(100% - 40px);

        &_double {
            margin: 0 -20px;

            @media screen and (min-width: $breakpointTabletLandscape) {
                display: flex;
                justify-content: space-between;

                .charts_column {
                    width: 50%;
                }
            }
        }
        &_double {
            display: flex;
            justify-content: space-between;
            margin: 0 -20px;

            @media screen and (min-width: $breakpointTabletLandscape) {
                display: flex;
                justify-content: space-between;

                .charts_column {
                    width: 50%;
                }
            }
        }
    }
    &_bloc {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 40px;
        background: $white;
        break-inside: avoid;

        .charts_bloc {
            padding: 0;
        }

        &_iceberg {
            width: 100%;
            @extend .flex;
            align-items: flex-start;
            &_legend {
                font-weight: 600;
                color: $takumi;
            }
        }
        &_donut {
            width: 100%;
            @extend .flex;
            align-items: flex-start;
        }
        &-title {
            color: $mainColor;
        }
        &-subtitle {
            margin-bottom: 20px;
            font-weight: 600;
            color: $takumi;
        }
        &-legend {
            margin-bottom: 20px;
            color: $darkGrey;
        }
        &-table_title {
            margin-top: 40px;
            margin-bottom: 10px;
            font-weight: 600;
            color: $takumi;
        }
        &_legend {
            &-list {
                &-item {
                    display: inline-block;

                    &-color,
                    &-label {
                        display: inline-block;
                        margin-bottom: 20px;
                        vertical-align: middle;
                    }
                    &-color {
                        margin-right: 10px;
                        width: 20px;
                        height: 20px;
                    }
                    &-label {
                        margin-right: 10px;
                    }
                }
            }
        }
        &_updown {
            display: flex;
            justify-content: flex-start;
            margin: 20px -20px;

            &.up,
            &.down,
            &.equal {
                position: relative;
                justify-content: space-between;

                &:after {
                    position: absolute;
                    content: ' ';
                    height: 30px;
                    width: 5px;
                    border-radius: 4px;
                    left: -22px;
                    top: 2px;
                }

            }
            &.up {
                &:after {
                    background: $accentGreen2;
                }
                .charts_bloc_updown-item-evolution {
                    &-symbol,
                    &-value {
                        color: $accentGreen2;
                    }
                    &-symbol {
                        .lnr {
                            transform: translate(-50%, -50%) rotate(-40deg);
                        }
                    }
                }
            }
            &.down {
                &:after {
                    background: $accentRed2;
                }
                .charts_bloc_updown-item-evolution {
                    &-symbol,
                    &-value {
                        color: $accentRed2;
                    }
                    &-symbol {
                        .lnr {
                            transform: translate(-50%, -50%) rotate(40deg);
                        }
                    }
                }
            }
            &.equal {
                &:after {
                    background: $accentOrange2;
                }
                .charts_bloc_updown-item-evolution {
                    &-symbol,
                    &-value {
                        color: $accentOrange2;
                    }
                }
            }
            &-item {
                margin: 0 20px;
                width: 33%;

                &-title,
                &-value,
                &-text {
                    display: block;
                }
                &-title {
                    font-weight: bold;
                    font-size: 1.8rem;
                }
                &-value {
                    font-weight: bold;
                    font-size: 2.5rem;
                }
                &-text {
                    color: $darkGrey;
                    font-weight: bold;
                    font-size: smaller;
                }
                &-evolution {
                    &-symbol,
                    &-value {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    &-symbol {
                        position: relative;
                        margin-right: 10px;
                        height: 35px;
                        width: 35px;
                        background: $grey;
                        text-align: center;
                        border-radius: 3px;

                        .lnr {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 2rem;
                            font-weight: bold;
                        }
                    }
                    &-value {
                        display: inline-block;
                        font-weight: bold;
                    }
                }
            }
            &.inline {
                float: right;
                margin-top: 0;

                &:after {
                    display: block;
                    clear: both;
                    content: '';
                }
                .charts_bloc_updown {
                    &-item {
                        width: 100%;
                    }
                }
                &:after {
                    left: 0;
                }
            }
        }
        &_percentages {
            &-list,
            &-total {
                &-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;

                    .progress {
                        &-bar {
                            display: inline-block;
                            width: calc(100% - 110px);
                            height: 7px;
                            background: $grey;
                            border-radius: 5px;
                        }
                        &-value {
                            height: 7px;
                            background-color: $darkGrey;
                            border-radius: 5px;
                        }
                    }
                    &-label {
                        width: 70px;
                        overflow-wrap: normal;
                    }
                    &-value {
                        width: 40px;
                        text-align: right;
                        font-size: 1.2rem;
                        font-weight: bold;
                    }
                }
            }
            &-list {
                column-count: 2;
                column-gap: 40px;
            }
            &-total {
                &-item {
                    .progress-value {
                        background-color: $takumiLight;
                    }
                }
            }
        }
        &_percentages_bubbles {
            &-list {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 10px;

                &-item {
                    padding: 10px;

                    .percentage {
                        &-bubble {
                            &-value {
                                display: block;
                                height: 60px;
                                width: 60px;
                                line-height: 2.2em;
                                padding: 10px;
                                text-align: center;
                                font-weight: bold;
                                font-size: 1.5rem;
                                background-color: $darkGrey;
                                border-width: 3px;
                                border-style: solid;
                                border-radius: 50%;

                                &.total {
                                    background-color: lighten($takumiLight, 40);
                                    border-color: $takumiLight;
                                    color: $takumiLight;
                                }
                            }
                        }
                    }
                }
            }
        }
        &_health {
            &_container {
                position: relative;
                width: 100px;
                height: 100px;
                margin: auto;
                margin-top: 40px;
            }
            &_gage {
                position: relative;
                width: 100px;
                height: 50px;
                overflow: hidden;
            }
            &_b1 {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                height: 100px;
                width: 100px;
                background: rgb(244, 245, 248);
                border-radius: 50% 50% 0 0;
                overflow: hidden;
            }
            &_b2 {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                height: 100px;
                width: 100px;
                &.minus {
                    transform: translateX(-50%) skewX(70deg);
                }
                &.equal {
                    transform: translateX(-50%);
                }
                &.plus {
                    height: 65px;
                    transform: translateX(-25%) skewX(-60deg);
                }
            }
            &_b3 {
                position: absolute;
                z-index: 3;
                top: 15px;
                left: 50%;
                height: 70px;
                width: 70px;
                background: rgb(255, 255, 255);
                border-radius: 50% 50% 0 0;
                transform: translateX(-50%);
            }
            &_b4 {
                position: absolute;
                z-index: 4;
                top: 50%;
                left: 50%;
                width: 15px;
                height: 15px;
                border-radius: 7.5px;
                background-color: #053d54;
                transform: translate(-50%, -50%);
            }
            &_b5 {
                position: absolute;
                z-index: 5;
                width: 7.5px;
                height: 7.5px;
                border-radius: 10px;
                background-color: #053d54;
                &.plus {
                    top: -1px;
                    left: 9px;
                }
                &.equal {
                    top: -4px;
                    left: 3px;
                }
                &.minus {
                    top: 0px;
                    left: -3px;
                }
            }
            &_text {
                position: absolute;
                z-index: 6;
                bottom: 20px;
                left: 50%;
                width: 50px;
                transform: translateX(-50%);
                text-align: center;
                font-weight: 600;
                color: $blueGrey;
            }
        }
        &-table {
            &.odd-even-columns {
                th:nth-child(2n+3),
                td:nth-child(2n+3) {
                    background: rgb(248,248,248);
                }
            }
            td {
                position: relative;
            }
            .charts_bloc_table-item-evolution-symbol {
                position: absolute;
                top: 6px;
                right: -12px;
                height: 35px;
                width: 35px;
                background: white;
                border: 1px solid $darkGrey;
                text-align: center;
                border-radius: 3px;
                z-index: 1;

                .lnr {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 2rem;
                    font-weight: bold;

                    &.equal {
                        color: $accentOrange2;
                    }
                    &.up {
                        color: $accentGreen2;
                        transform: translate(-50%, -50%) rotate(-40deg);
                    }
                    &.down {
                        color: $accentRed2;
                        transform: translate(-50%, -50%) rotate(40deg);
                    }
                }
            }
        }
    }
}
