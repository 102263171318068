.grid {
    display: flex;
    justify-content: space-between;
    clear: both;

    &.align-baseline {
        align-items: baseline;
    }

    &.align-end {
        align-items: flex-end;
    }

    &.align-middle {
        align-items: center;
    }

    & > * {
        width: 100%;
        margin-left: 20px;

        &.align-baseline {
            align-self: baseline;
        }

        &.align-end {
            align-self: flex-end;
        }

        &.align-middle {
            align-self: center;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    & .grid {
        @for $i from 1 through 11 {
            &-#{$i} {
                flex: $i
            }
        }
    }
}
