.list {
    &_toolbar {
        margin-bottom: 30px;
        text-align: right;

        &-form {
            padding: 30px;
            text-align: left;

            &-part {
                @extend .flex;
                align-items: flex-start !important;
                clear: both;

                & > * {
                    width: 100%;
                }
            }
            label:not(.checkbox-input) {
                margin-bottom: 5px;
            }
            .input-container {
                margin-top: 10px;
            }
            button.advanced-search {
                float: right;
                margin-bottom: 20px;
            }
        }
    }

    &_actions {
        margin-bottom: 10px;
    }

    &_empty {
        text-align: center;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    padding: 1px 5px;
    color: inherit;
    cursor: help;

    &-text {
        position: absolute;
        display: none;
        top: -8px;
        left: 25px;
        z-index: 1;
        padding: 10px;
        border-radius: 6px;
        color: white;
        white-space: nowrap;
        background-color: $black;
    }
    &:hover {
        & .tooltip-text {
            display: block;
        }
    }
}
