#flash-message--root {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10000;
}

.flash-message {
    min-width: 200px;
    padding: 20px;
    border-radius: 4px;
    background: $white;
    box-shadow: $defaultShadow;
    transition: 0.3s all;
    transform-origin: 100% 0;

    &--hide {
        opacity: 0;
        transform: scale(0);
    }
}
