$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$darkGrey : rgb(190, 190, 190);
$grey : rgb(237, 237, 237);
$blueGrey: rgb(178, 193, 200);
$takumi: rgb(0, 49, 69);
$takumiLight: rgb(114, 138, 149);
$takumiLighter: rgb(185, 198, 204);

$accentLightGreen1: rgb(162, 173, 0);
$accentLightGreen2: rgb(214, 227, 66);
$accentGreen1: rgb(52, 178, 51);
$accentGreen2: rgb(146, 212, 0);
$accentBlueGreen1: rgb(0, 165, 153);
$accentBlueGreen2: rgb(71, 213, 205);
$accentBlue1: rgb(0, 152, 219);
$accentBlue2: rgb(94, 182, 228);
$accentPurple1: rgb(207, 0, 114);
$accentPurple2: rgb(235, 103, 185);
$accentRed1: rgb(198, 12, 48);
$accentRed2: rgb(247, 64, 58);
$accentOrange1: rgb(255, 88, 0);
$accentOrange2: rgb(255, 161, 0);
$accentYellow1: rgb(253, 200, 47);
$accentYellow2: rgb(250, 231, 0);
$accentGrey1: rgb(170, 163, 142);
$accentGrey2: rgb(190, 185, 166);
$accentKhaki1: rgb(110, 118, 69);
$accentKhaki2: rgb(144, 152, 107);

$suzukiRed: rgb(213, 43, 30);

$mainColor: $suzukiRed;
$secondaryColor: $accentBlue1;

$defaultShadow: 0 0 7px rgba(0, 0, 0, 0.01);
$darkShadow: 0 0 7px rgba(0, 0, 0, 0.05);

$headerHeight: 80px;
$footerHeight: 40px;

$checkboxSize: 25px;

$breakpointDesktop: 1200px;
$breakpointTabletLandscape: 1024px;
