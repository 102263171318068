.chart {
    display: block;
    width: 100%;
    height: 100%;

    &-donut {
        &:not(:first-of-type) {
            border-left: 1px solid #b2c1c7;
        }
        &-title {
            font-size: 1.4rem;
            font-weight: bold;
            text-align: center;
            color: $takumi;
        }
        &-legend {
            font-size: 1rem;
        }
    }

    .domain {
        stroke: #CCC;
    }
    .grid {
        .tick {
            line {
                stroke: #CCC;
            }
        }
        text,
        .domain {
            visibility: hidden;
        }
    }
    [data-axis] {
        .tick {
            line {
                visibility: hidden;
            }
        }
    }
    [data-axis="y"] {
        .domain {
            visibility: hidden;
        }
    }
    .tooltips {
        &-total {
            g:last-of-type {
                rect:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.tooltip {
    &-value,
    &-total {
        position: absolute;
        display: none;
        text-align: center;
        background: white;
        opacity: 0;
        z-index: 10;
    }
}

.text-outline {
    paint-order: stroke;
    stroke: #000000;
    stroke-width: 1px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    font-weight: 800;
}
